<template>
  <div style="background-color: #014194" class="py-5">
    <section>
      <div id="certificaciones-isos" class="py-5">
        <h2 class="text-center py-5" style="font-size: 1.9em; color: #fff">
          Visítanos en Nuestras Oficinas
        </h2>

        <div class="container">
          <div class="row pt-5">
            
            <v-carousel v-model="model" hide-delimiters height="100%">
              <v-carousel-item v-for="(slide,index) in slides" :key="index">
                <div class="row align-items-center">
                  <div class="col-md-4 offset-md-1 px-5">
                    <v-img data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" loading="lazy"
                      :src="require(`@/assets/${slide.image}`)" class="rounded-image w-100 m-auto"></v-img>
                  </div>
                  <div class="col-md-6 px-5 container-iso-description">
                    <!-- <h5 style="font-size: 2em; color: #fff">Descripción</h5> -->
                    <p data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                      class="mr-5 text-justify" style="
                        max-block-size: 245px;
                        text-overflow: ellipsis;
                        color: #fff;
                        overflow: hidden;
                      " v-html="slide.description">
                      
                    </p>
                    <!-- <v-btn color="#fc5203" class="more-info-button" @click="abrirModal(iso)">
                      <div class="text-capitalize text-white">Más </div>
                      <div class="text-lowercase text-white pl-1"> información</div>
                    </v-btn> -->
                  </div>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </div>
    </section>
    <!-- <section v-else>
      <h1>CARGANDO...</h1>
    </section> -->

    <!-- <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h5>{{ this.isoModal.name }}</h5>
          <v-spacer></v-spacer>
          <v-btn color="#fcc404" @click="dialog = false"> x </v-btn>
        </v-card-title>

        <div v-if="validate(this.isoModal.intro)">
          <v-card-subtitle>
            <strong>Introducción</strong>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            {{ this.isoModal.intro }}
          </v-card-text>
        </div>

        <div v-if="validate(this.isoModal.time_obtaining)">
          <v-card-subtitle>
            <strong>Tiempo de obtención</strong>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            {{ this.isoModal.time_obtaining }}
          </v-card-text>
        </div>

        <div v-if="validate(this.isoModal.time_validity)">
          <v-card-subtitle>
            <strong>Tiempo de validez</strong>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            {{ this.isoModal.time_validity }}
          </v-card-text>
        </div>

        <div v-if="validate(this.isoModal.steps_implementation)">
          <v-card-subtitle>
            <strong>Pasos de implementación</strong>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            {{ this.isoModal.steps_implementation }}
          </v-card-text>
        </div>

        <div v-if="validate(this.isoModal.benefits)">
          <v-card-subtitle>
            <strong>Beneficios</strong>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            {{ this.isoModal.benefits }}
          </v-card-text>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#fcc404" @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: 0,
      dialog: false,
      slides: [{
        image:'inprocal-oficinas.jpg',
        description:"Estamos ubicados en:  Av. La Fontana, 440 - C.C. La Rotonda II - 2086, La Molina 15011, Lima/Perú<br><br>Horario de Atención en oficina de Lunes a Viernes 11:00am a 7:00pm<br><br>Teléfono Fijo: 12910193"}],
      isoModal: [],
      // initialLoading: false,
      url: "https://ipssoma-storage.s3.eu-west-1.amazonaws.com/",
    };
  },

  mounted() {
    // this.listIsos();
  },

  methods: {
    concat: function (img) {
      return `${this.url}${img}`;
    },

    validate: function (value) {
      return value != undefined && value != "null" ? true : false;
    },

    abrirModal: function (iso) {
      this.isoModal = iso;
      this.dialog = true;
    },

    // listIsos: function () {
    //   this.initialLoading = true;
    //   this.$axios.get(`/public/isos/list`).then((response) => {
    //     this.isos = response.data;
    //     this.initialLoading = false;
    //   });
    // },
  },
};
</script>

<style>
@media (min-width: 0px) and (max-width: 571px) {

  .container-iso-description h5,
  p,
  .more-info-button {
    margin-left: 20px;
  }
}

.rounded-image {
  border-radius: 5%;
}

* {
  font-family: "Segoe UI", Helvetica, Arial, sans-serif;
}
</style>
