<template>
  <div class="container-fluid" id="nosotros">
    <h2 class="font text-center py-2">Conoce el Instituto Profesional de Capacitación Laboral</h2>
    <div class="row">
      <div class="col-sm-12 col-lg-6" style="background-color: #014194" >
        <h2 style="font-size: 1.9em; color: #fff" class="mt-5 text-center py-5">
          ¿Quiénes somos?
        </h2>
        <div class="px-5"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <p class="text-justify" style="color: #fff">
            Somos una institución nacida en el 2018, con la modalidad E-learning, 
            proponiendo una educación: rápida, flexible y de calidad, formando 
            especialistas en el área de Seguridad y Salud en el trabajo, Medio 
            Ambiente y Calidad, con una excelente plana docente y una malla 
            curricular actualizada.
          </p>
          <p class="text-justify" style="color: #fff">
            Desde nuestros inicios a la fecha, más de 5.000 alumnos han logrado 
            capacitarse, certificarse y potenciar sus conocimientos con nuestros 
            Cursos y Diplomados.
          </p>
        </div>

        <div class="text-center my-5">
          <v-btn
            to="/historia"
            rounded
            color="#fc5203"
            light
            style="font-weight: bolder; padding: 25px"
          >
            <div class="text-capitalize text-white">Conoce </div> 
            <div class="text-lowercase text-white pl-1"> toda la historia detrás</div></v-btn
          >
        </div>
      </div>

      <div
        class="col-sm-12 col-md-12 col-lg-6 text-center"
        style="background-color: #fc5203"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <img
                data-aos="zoom-in"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                class="img-fluid img-not-selectable"
                src="../../../assets/Ing_Giovany_Pernia.webp"
                alt="Ing.Giovany Pernia"
              />
              <div>
                <span class="text-white"><b>Ing.Giovany Pernia</b></span>
                <p class="text-white">Director-Gerente</p>
              </div>
            </div>
            <!-- <div class="col-6 text-center">
              <img
                data-aos="zoom-in"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                class="img-fluid img-not-selectable"
                src="../../../assets/Dilu_Pereda.webp"
                alt="Dilu Pereda"
              />
              <div>
                <span><b>Dilu Pereda</b></span>
                <p>Gerente de Planificación <br> y Gestión del IPSSOMA</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.img-not-selectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}
</style>
