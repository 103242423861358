<template>
  <div id="main">
    <vueper-slides
      fractions
      :bullets="false"
      progress
      :slide-ratio="1 / 4"
      :fixed-height="sliderHeight"
      fade
      :touchable="false"
      autoplay
      duration="2500"
    >
      <template #arrow-left>
        <v-icon large color="#fc5203"> mdi-arrow-left </v-icon>
      </template>

      <vueper-slide
        class="vueperslides-bg-image-size"
        loading="lazy"
        v-for="(slide, index) in slides"
        :key="index"
        :image="slide.image"
      />

      <template #arrow-right>
        <v-icon large color="#fc5203"> mdi-arrow-right </v-icon>
      </template>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "AnnouncementSlider",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      sliderHeight: "",
      slides: [],
    };
  },
  methods: {
    screen() {
      let screenWidth = screen.width;
      if (screenWidth < 576) {
        this.sliderHeight = "200px";
      } else if (screenWidth >= 576 && screenWidth < 768) {
        this.sliderHeight = "400px";
      } else if (screenWidth >= 768) {
        this.sliderHeight = "580px";
      }
    },
    async getBanners(){
      await this.$axios.get('/public/banners/list').then((response) => {
        this.slides = response.data;
        console.log(response.data)
      })
    }
  },
  mounted() {
    this.screen();
    this.getBanners();
  },
};
</script>

<style>
.vueperslides__progress {
  background: rgba(0, 0, 0, 0.25);
  color: #fc5203;
}

.vueperslides__fractions {
  font-weight: 600;
  background: #fc5203;
  color: #000;
}
</style>
