<template>
  <div id="certificado" class="my-16" style="border: 3px solid #fc5203; border-radius: 15px; margin: 50px;">
    <div class="container-fluid">
      <div class="row py-5 px-3 mb-5 align-items-center" style="padding: 0px">
        <div
          class="col text-center mx-auto"
          
          data-aos="zoom-in-up"
          data-aos-offset="500"
          data-aos-easing="ease-in-sine"
        >
          <h5
            class="py-5 px-5"
            style="font-size: 1.9em; font-weight: 600; color: #000"
          >
            Verificador de certificado
          </h5>
          <v-form ref="form" v-model="valid" lazy-validation class="px-5">
            <v-select
              light
              outlined
              v-model="documento"
              item-text="name"
              item-value="id"
              :items="items"
              :rules="[(v) => !!v || 'Tipo de documento es requerido']"
              label="Tipo de documento"
              required
            ></v-select>
            <v-text-field
              light
              v-model="nrodocumento"
              :rules="nrodocumentoRules"
              label="Número de documento"
              required
              outlined
            ></v-text-field>

            <v-btn
              :disabled="valid == false"
              color="#fc5203"
              class="mr-4 font-weight-bold mt-4 text-capitalize text-white"
              @click="validate"
            >
              Verificar
            </v-btn>
          </v-form>
        </div>
        <div
          class="d-none d-md-block col-6 p-5"
        >
          <img :src="require('@/assets/certificado.jpg')" class="img-fluid">
        </div>
      </div>
    </div>
    <!-- <div>
      <h4 class="bg-warning text-center font">VERIFICADOR DE CERTIFICADO</h4>
      <div class="certificado-info">
        <label class="font" for="documento">Tipo de documento: </label>
        <select
          class="form-select btn-lg btn-lg btn-light"
          name="document_id"
          id="document_id"
          v-model="documento"
        >
          <option value="1">DNI</option>
          <option value="3">Carnet de extranjeria</option>
          <option value="2">Pasaporte</option>
        </select>
      </div>
      <div class="certificado-info mt-4">
        <label class="font" for="nrodocumento">Número de documento: </label>
        <input
          class="form-control font"
          type="number"
          id="nrodocumento"
          name="nrodocumento"
          v-model="nrodocumento"
        />
      </div>
      <button
        class="btn btn-dark btn-lg mx-auto mt-4 font"
        type="button"
        @click="checkCertified"
      >
        Verificar
      </button>
    </div> -->

    <div class="text-center">
      <v-dialog v-model="mostrarImagen" width="60%">
        <v-card>
          <v-card-text>
            <div class="text-center pt-2">
              <strong>Certificados digitales de Inprocal</strong>
              <p>Bienvenido: </p>
            </div>
            <div class="text-center">
              <div class="table-responsive">
                <table class="table table-primary bg-white">
                  <thead>
                    <tr>
                      <th scope="col">Certificado</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in userData" :key="data.id" class="">
                      <!-- <td>{{data.path}}</td> -->
                      <td class="">
                        <div class="">
                          <img load="lazy" :src="data.path" alt="" class="img-fluid">
                        </div>
                        <!-- <a
                          class="btn btn-primary mx-2 text-white"
                          download="certificado"
                          :href={{data.path}}
                          >Descargar</a
                        > -->
                        <!-- <a :href="data.path" download="archivo.jpg">Descargar</a> -->
                        <button @click="download(data.id)" class="btn btn-primary mt-1">Descargar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <!-- <img
                class="img-fluid"
                :src="'data:image/jpeg;base64, ' + imagen"
              /> -->
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-danger mx-2" @click="mostrarImagen = false">
              Cerrar
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="mostrarMensaje" width="40%">
        <v-card>
          <v-card-text>
            <div class="text-center pt-4">
              <strong>No se encontraron resultados</strong>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-danger mx-2" @click="mostrarMensaje = false">
              Cerrar
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      items: [
        { name: "DNI", id: 1 },
        { name: "Pasaporte", id: 2 },
        { name: "Carnet de extranjeria", id: 3 },
        { name: "Otros", id:4 },
      ],
      documento: "",
      nrodocumento: "",
      mostrarImagen: false,
      mostrarMensaje: false,
      imagen: null,
      userData: [],
      nrodocumentoRules: [
        (v) => !!v || "Nro. de documento requerido",
        (v) =>
          (v && v.length >= 8) ||
          "El documento debe tener al menos 8 caracteres",
      ],
    };
  },

  methods: {
    download(id) {
      this.$axios({
        url: `/public/sstclient/download-certificate?id=${id}`,
        method: 'GET',
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL
              .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.checkCertified();
        this.form = {};
        this.reset();
      } else {
        console.log("error");
      }
    },
    reset() {
      this.$refs.form.reset();
    },

    async checkCertified() {
      const form = {
        doc_type_id: this.documento,
        num_doc: this.nrodocumento,
      };
      await this.$axios.post("/public/sstclient/client-info", form).then((response) => {
        console.log(response);
        if(response.data.length == 0){
          this.mostrarMensaje = true;
        }else{
          this.userData = response.data;
          this.mostrarImagen = true;
        }
        // if (response.data == "sin registros") {
        //   this.mostrarMensaje = true;
        // } else {
        //   this.imagen = response.data.image_encoded;
        //   this.userData = response.data.client;
        //   this.mostrarImagen = true;
        // }
      });
    },
  },
};
</script>

<style>
/* #certificado {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
} */
/* 
.font {
  font-family: Roboto !important;
} */

/* #certificado form {
  border: 3px solid #ffbf00;
  border-radius: 15px;
  width: 550px;
  padding: 20px;
  display: flex;
  flex-direction: column;
} */

/* #certificado .certificado-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 481px) and (max-width: 767px) {
  #certificado form {
    border-radius: 5px;
    width: 300px;
    padding: 10px;
  }
} */
</style>
